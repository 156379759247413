import {ChangeEvent, FC, useState} from 'react'
import {IChannelPaymentOnCard} from './IChannelPaymentOnCard'
import {Checkbox, Stack, Tooltip, Typography} from '@mui/material'
import {CreditScore} from '@mui/icons-material'
import channelCardStore from '../../../../../../store/channelCard.store'
import channelTableStore from '../../../../../../store/channelTable.store'
import {IUpdateStatusesRequest} from '../../../../../../services/integration/IntegrationsRequest'
import ChannelService from "../../../../../../services/channel/channel.service";
import {observer} from "mobx-react-lite";

export const ChannelPaymentOnCard: FC<IChannelPaymentOnCard> = observer((props) => {
  const {
    isPaymentOnCard,
    id,
    update_id,
    readOnly,
    onChangeUpdateId
  } = props

  const [isLoading, setLoading] = useState(false)

  const changeCheckbox = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const option: IUpdateStatusesRequest = {
      id,
      update_id,
      field: 'isPaymentOnCard',
      new_value: e.target.checked as any
    }

    try {
      const res = await ChannelService.updateSimpleField(option)
      channelCardStore.setPaymentOnCard(res.data.isPaymentOnCard)
      channelTableStore.changeField({
        id,
        update_id: res.data.update_id,
        field: 'isPaymentOnCard',
        new_value: e.target.checked as any
      })
      onChangeUpdateId(res.data.update_id)
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Checkbox checked={isPaymentOnCard} onChange={changeCheckbox} disabled={isLoading || readOnly}/>
      <Tooltip title={<Typography>Оплата канала на карту</Typography>}>
        <CreditScore color={isPaymentOnCard ? 'primary' : 'disabled'}/>
      </Tooltip>
    </Stack>
  )
})
